body[data-template=home] .section--article-list .article-list__item a .card-header .card-date,
.top-topics__item a,
.event-list__item a,
.content-navigation__caption a,
.social a, 
.pimcore_editable a,
.x-menu-item a,
.x-window a,
a.streched-link,
.logo__inner a {
    span {
        background-image: none;
        padding-bottom: 0;
    }

}

.accessibiblity a span[aria-hidden="true"] {
    background-image: linear-gradient(0deg,
            $color__white 0%,
            $color__white 0%);
}
.card-text__contact .icon--fluid,
a.stretched-link,
a.stretched-link span,
.accessibiblity a span.icon {
    background-image: none;
}


.footer-bottom a span {
    background-image: linear-gradient(0deg,
            $color__white 0%,
            $color__white 0%);
}

.footer-bottom .meta-navigation__button{
    text-decoration: none;
    span{
        background-image: linear-gradient(0deg,
                $color__white 0%,
                $color__white 0%);
        position: relative;
    
        background-color: rgba(255, 255, 255, 0);
    
        background-repeat: no-repeat;
        background-size: 100% 2px;
        background-position: left bottom;
    
        padding-bottom: 4px;
    }
    @include hover-focus-visible{
        span {
            background-size: 30px 2px;
        }
    }
} 
