html.js .main-navigation__link{
    a{
        &::before{
            display: none; 
        }
    }
}

.off-canvas__footer a.button{
    align-items: center;
    span{
        background-image: none;
        padding-bottom: 0;
    }
}