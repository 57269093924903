.dgf-list .card-text{
    align-items: flex-start;
}
.section--bg-light-with-icon{
    background-color: $color__gray--light;
    position: relative;
    // overflow: hidden; 
    >.container-xxl{
        position: relative;
        z-index: 1;
    }
    &:before{
        max-width: 385px;
        max-height: 100%;
        content: "";
        position: absolute;
        right: 50px;
        bottom: 64px;
        z-index: 0;
        // width: 100%;
        height: 100%;
        aspect-ratio: 161 / 150;
        background-image: url('/assets/website/img/logo/weil_der_stadt_tuerme.svg');
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
        
    }
    @media(max-width: 500px){
        padding-bottom: 120px!important;
        &:before{
            height: 80px;
            bottom: 20px;
            right: 20px;
        }
    }
}
