.splide__arrows .splide__arrow.splide__arrow--prev{
    background-color: $color__white;
    span.icon img{
        filter: $filter__primary;
    }
    @include hover-focus-visible{
        background-color: $color__primary--hover;
        span.icon img{
            filter: $filter__white;
        }
    }
}
