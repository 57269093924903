body .citizen-service-portal-frequently-searched__item{
    background-color: $color__gray--light;
}
body[data-template="citizen-service-portal"]{
    .section--citizen-service-portal-intro{
        ul{
            padding: 0;
            margin: 0;
            li{
                list-style: none;
                a.button{
                    padding-top: 16px ;
                    padding-bottom: 16px;
                    background-color: $color__primary;
                    background-image: none;
                    span{
                        background-image: none;
                    }
                    @include hover-focus-visible{
                        background-color: $color__primary--hover;
                        span{
                            background-image: none;
                        }
                    }
                }
            }
        }
    }
    .section--citizen-service-portal-not-found{
        .list-styled{
            a span{
                background-image: none;
                padding-bottom: 0;
                
            }
        }
    }
    ul.list-styled{
        margin-left: 22px;
        li{
            list-style: disc;
            padding: 0;
            &::before{
                display: none;
            }
            a{
                text-decoration: none;
                position: relative;

                background-color: rgba(0, 0, 0, 0);
                background-image: linear-gradient(0deg,
                        $color__black 0%,
                        $color__black 0%);
                background-repeat: no-repeat;
                background-size: 100% 2px;
                background-position: left bottom;
        
                padding-bottom: 2px;
        
                @include transition();
        
                &:hover,
                &:focus-visible {
                    background-size: 30px 2px;
                }
        
            }
        }
    }
    h1{
       font-family: $font__family--heading;
       max-width: 430px;
       line-height: 1.1;
       font-family: "FaveScriptPro";
       font-weight: $font__weight--normal;
       @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 64px, 100px);
       @include media-breakpoint-down(sm) {
           margin-bottom: 2rem;
       }
    } 
    .button{
        @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 16px, 22px);
    } 
    .citizen-service-portal-found-quickly__item{
        background-color: $color__primary--hover; 
        a{
            span{
                background-color: none;
                padding-bottom: 0;
                background-size: unset;
            }
        }
    }
}